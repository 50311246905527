<template>
  <div
    class="flex h-[530px] w-full max-w-[242px] flex-col items-center justify-center text-center text-14"
    :class="{ '!h-[570px]': getProviderStatus(STRIPE) }"
    :style="textStyle"
  >
    <div class="rounded-16 bg-[#F5F5F5] p-24">
      <div>
        <span class="font-semibold">{{ translate('generate.pages.payment.formError.title', locale) }}&nbsp;</span>
        <span>{{ translate('generate.pages.payment.formError.desc', locale) }}</span>
      </div>
      <Button
        v-if="!isEditorMode"
        class="mt-32 flex min-w-[200px] items-center justify-center"
        tag-name="button"
        :value="translate('generate.pages.payment.formError.retry', locale)"
        :page-data="pageData"
        :page-options="pageOptions"
        @click="globalEmit('integrationRetryLoad')"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type PropType, computed } from 'vue';
import { useTranslate } from '@shared/composable/useTranslate';
import { type PaymentPageData, PaymentProvider } from '@shared/elements/common/payment/types';
import type { PageOptions } from '@shared/types/model';
import { globalEmit } from '@shared/utils/helpers';
import Button from '@shared/elements/button/index.vue';

const props = defineProps({
  isEditorMode: { type: Boolean, default: true },
  pageData: { type: Object as PropType<PaymentPageData>, default: () => ({}) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  locale: { type: String, default: '' }
});

const { STRIPE } = PaymentProvider;
const { translate } = useTranslate();

function getProviderStatus(provider: PaymentProvider) {
  return !!props.pageData.providerStatus?.[provider];
}

const textStyle = computed(() => {
  const color = props.pageOptions.colors?.text[0];
  const fontFamily = props.pageOptions.fontFamily;
  return {
    color,
    fontFamily
  };
});
</script>
